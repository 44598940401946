<template>
    <div>
        <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`" class="whatsapp-button">
            <img src="@/assets/whatsapp-32.svg" alt="" width="32" height="32">
        </a>
    </div>
    <footer class="footer-section">
        <div class="footer-content">
            <div class="row">
                <div>
                    <h3>Sobre Nós</h3>
                    <P>Zona Leste Guincho - <strong style="color:#7F55C4">Seu serviço de reboque de confiança 24 horas por dia</strong>. Estamos sempre prontos para atendê-lo, seja de dia ou à noite, oferecendo serviços ágeis, eficientes e seguros em toda a Zona Leste de São Paulo. Nossa equipe especializada está equipada para lidar com emergências, garantindo que seu veículo seja transportado com total cuidado e segurança. Com a Zona Leste Guincho, você pode contar com um atendimento de excelência e tranquilidade a qualquer momento.</P>
                </div>
                <div>
                    <h3>Serviços</h3>
                    <ul class="servicos">
                        <li><router-link to="/guincho-24-horas/">Guincho 24 horas</router-link></li>
                        <li><router-link to="/troca-de-pneus/">Troca de pneus</router-link></li>
                        <li><router-link to="/carga-na-bateria/">Carga na bateria</router-link></li>
                        <li><router-link to="/pane-seca/">Pane seca</router-link></li>
                    </ul>
                </div>
                <div>
                    <h3>Informações Do Negócio</h3>
                    <div class="contact-info">
                        <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${tel}&amp;text=${msg}`"
                            name="whatsapp" class="icon-text">
                            <i class="fab fa-whatsapp"></i>
                            <span>{{ formatPhoneNumber(tel) }}</span>
                        </a>
                        <a target="_blank" :href="`tel:${tel}`" class="icon-text tel"><i class="fas fa-phone"></i><span>{{
                            formatPhoneNumber(tel) }}</span></a>
                        <address><a target="_blank" :href="`mailto:${email}`" class="icon-text email"><i
                                    class="far fa-envelope-open"></i><span>{{ email }}</span></a></address>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="">
                <p>&copy; {{ year }} - Todos os direitos reservados</p>
            </div>
        </div>
    </footer>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
export default {
  name: 'FooterComponent',
  data () {
    return {
      year: new Date().getFullYear(),
      tel: process.env.VUE_APP_WHATSAPP_NUMBER,
      email: process.env.VUE_APP_EMAIL,
      msg: process.env.VUE_APP_MSG,
      screenWidth: window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    formatPhoneNumber (number) {
      const cleaned = ('' + number).replace(/\D/g, '')

      if (cleaned.length === 13) {
        return `(${cleaned.slice(2, 4)}) ${cleaned.slice(4, 5)} ${cleaned.slice(5, 9)}-${cleaned.slice(9)}`
      } else {
        return number
      }
    },
    handleResize () {
      this.screenWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
a{
    color:#d5d5d5!important
}
i{
    padding: 7px
}
.footer-content{
    min-height: 500px!important;
    padding: 0 5px;
}
.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 18px;
    margin-right: 5px;
    color: #d5d5d5;
    font-size: 10px;
    text-align: center;
    background-color: #f3f3f6;
    vertical-align: middle;
    border-radius: 50%;
}
.row {
    display: flex;
    position: relative;
    width: 95%;
    margin: 0 auto!important;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 6px;
    div{
        // background: #0dd134;
        width: calc(95%/3);
        @media only screen and (max-width: 600px) {
            width: 95%;
    }
    }
}
*{
    overflow: hidden!important;
}
h3 {
    @media only screen and (max-width: 600px) {
            font-size: 28px;
    }
    font-family: "Teko", Sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 58px;
    color:#7F55C4
}
.footer-section {
    margin-top: 100px;
    background: #272d3b;
    color: #d5d5d5
}

.contact-info{
    min-width: 100%;
    .icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color:#d5d5d5!important
}
}

.contact-info .icon-text i {
    margin-right: 10px;
    font-size: 20px;
    color: #7F55C4;
}

.subscribe-form input[type="email"] {
    width: 60%;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.subscribe-form button {
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    background-color: #ea8500;
    height: 40.8px;
    margin-left: 2px;
    color: #d5d5d5;
    font-size: 16px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.subscribe-form button:hover {
    background-color: #e64906;
}

.footer-social-icon {
    margin-top: 20px;
}

.footer-social-icon span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.footer-social-icon a {
    color: #d5d5d5;
    font-size: 20px;
    margin-right: 10px;
}

.footer-social-icon a:hover {
    color: #7F55C4;
}

.footer-menu {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center
}

.footer-menu li {
    margin: 0 10px;
}

.footer-menu li a {
    color: #d5d5d5;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-menu li a:hover {
    color: #ea8500;
}

.copyright-area {
    padding-top: 80px;
    padding-bottom: 4px;
    background-color: #202531;
    color: #d5d5d5;
    text-align: center;
    div{
        margin: 0 auto!important;
    }
}
.copyright{
    display: flex;
    justify-content: center;
}
@media (max-width: 767px) {
    .footer-menu {
        margin-top: 30px;
    }
}
.whatsapp-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        background-image: radial-gradient(at center center, #009e17 20%, #0DD135 90%);
        width: 48px;
        height: 48px;
        padding: 20px;
        font-size: 16px;
        border-radius: 100%;
        text-decoration: none;
        animation: pulse 2s infinite;
        box-shadow: 0px 0px 21px -6px #25D366;
        // animation: pisca 1.5s infinite;
        transition: background-color 0.3s ease;
        position: fixed;
        bottom: 2vh;
        right: 5vw;
        @media only screen and (max-width: 600px) {
            bottom: 4vh;
        }
        z-index: 1000;
    }

    .whatsapp-button:hover {
        background-color: #128C7E;
    }

    .whatsapp-button i {
        margin-right: 10px;
        font-size: 20px;
    }
</style>
