<template>
  <header>
    <nav>
      <div>
          <img src="@/assets/logo.png" width="120" alt="logo" />
      </div>
      <div>
        <svg id="toggle-menu" class="toggle-menu" v-on:click="menu" xmlns="http://www.w3.org/2000/svg" width="36"
          height="36" fill="#7F55C4" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
        </svg>
      </div>
      <div class="subnav" :class="{ 'menu-active': isMenuOpen }">
        <ul id="subnav" class="">
          <svg :style="{ position: 'absolute', top: '2vh', right: '2vh' }" v-on:click="menu"
            xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#7F55C4" class="bi bi-x-lg"
            viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
          <li class="">
            <router-link class="nav__link" v-on:click="menu" to="/">
              Home
            </router-link>
          </li>
          <li class="">
            <router-link v-on:click="menu" to="/a-empresa/" class="m">A Empresa</router-link>
          </li>
          <li class="">
            <router-link to="/servicos/" v-on:click="menu" class="m">Serviços</router-link>
          </li>
          <li class="">
            <router-link to="/galeria-de-fotos/" v-on:click="menu" aria-current="page" class="m m-active">
              Galeria
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'header-cps',
  data () {
    return {
      isMenuOpen: false
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      localStorage.setItem('width', window.innerWidth)
    },
    menu () {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>
<style lang="scss" scoped>
.header-top {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.subnav {
  background-color: #272d3b;
  position: absolute;
  top: 0;
  align-items: center;
  right: 10vw;
  transition: left 0.3s ease;
  z-index: 999999;
  max-height: 48px !important;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    position: fixed;
    top:0;
    border-radius: 0px;
    min-height: 100vh !important;
    min-width: 100vw !important;
    background-color: #272d3b;
    background: #272d3b;
    left: -100%;

    &.menu-active {
      left: 1vw;
      z-index: 2;
    }
  }

  ul {
    * {
      color: #7F55C4 !important;
    }

    display: flex;

    @media only screen and (max-width: 600px) {
      * {
        color: #fff !important;
      }

      flex-direction: column;
    }

    flex-direction: row;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      a:hover {
        border-bottom: 2px solid #7F55C4 !important;
        color: #7F55C4 !important;
      }
    }
  }
}

.toggle-menu {
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 18%;
    right: 5vw;
    margin: 0;
    padding: 0;
  }

  border: none;
  cursor: pointer;
}
svg{
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
nav {
  display: inline-flex;
  position: absolute;
  top: 1vh;
  left: 5vw;
  padding-left: 10vw !important;
  z-index: 99999 !important;
  border-radius: 32px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  min-width: 80vw !important;
  max-width: 80vw !important;
  min-height: 48px !important;
  background-color: #272d3b;
  padding:2px;
  grid-template-columns: repeat(2, calc(80vw/2));

  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-rows: repeat(2, calc(80vw/2));
    background-color: #272d3b;
    background: #272d3b;
  }

  a {
    padding:20px;
  }
}

.navbar {
  /* *{
    min-width: 56px;
    min-height: 56px;
  } */
  position: absolute;
  top: 1vh;
  left: 5vw;
  padding-left: 10vw !important;
  z-index: 99999 !important;
  border-radius: 32px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  min-width: 80vw !important;
  max-width: 80vw !important;
  min-height: 48px !important;
  background-color: #272d3b;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    justify-content: stretch;
    flex-direction: column;
    padding: 0;
  }
}

.nav-menu {
  @media only screen and (max-width: 600px) {
    border-radius: 0;
    background: #333;
    color: #fff !important;
  }

  display: grid;
  transition: all 0.25s ease-in;
  grid-template-columns: repeat(5, calc(55vw/5));

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 100%);
  }

  column-gap: 5px;
  row-gap: 2.5px;
  text-align: center !important;

  @media screen and (max-width: 768px) {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(-100%);

    &.menu-active {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .menu-item a {
    display: block;
    line-height: 30px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

.sub-nav {
  display: none;
  position: absolute;
  padding: 5px 5px;
  list-style: none;
  width: 230px;
}

.nav__link {
  color: #fff;
  text-decoration: none;

  &:hover+.sub-nav {
    display: block;
  }
}

.sub-nav {
  &:hover {
    display: block;
  }
}
</style>
