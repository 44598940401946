<template async>
  <nav>
    <Header/>
  </nav>
  <router-view class="router"/>
  <Footer/>
</template>
<script async>
export default {
  name: 'app-main',
  components: {
    Header: require('@/components/Header.vue').default,
    Footer: require('@/components/Footer.vue').default
  }
}
</script>
<style async lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
.title {
    color:#7F55C4;
    font-family: "Teko", Sans-serif;
    font-size: 70px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 58px;
    letter-spacing: -2.9px;
    padding-left: 4px;
}
/* @import  url(../src/css/main.min.css) screen and (min-width: 1080px);
@import url(../src/css/main.min.css) screen and (min-width: 640px);
@import url(../src/css/main.min.css) screen and (min-width: 256px); */
@import './scss/main.scss';

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap'); */
</style>
